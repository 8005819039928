body {
  background-color:#F6F1EE;
}

.App {
  text-align: left;
  overflow:hidden;
}

.ViewCanvas {
  width: 100%;
  height:100%;
}

.RightInfo {
  width: 360px;
  position: fixed;
  height:100%;
  top:58px;
  overflow: hidden;
  background-color:#FFF;
  opacity:0.8;
  border:1px solid #CCC;
  right:0;
}

#RightInfoContainer.collapsed {
  width:50px;
}

#RightInfoContainer.collapsed #menuContent {
  display:none;
}

.Menu {
  width: 300px;
  height:500px;
  right:0px;
  position:fixed;
  overflow:scroll;
  padding:0px 30px 30px 30px;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(500px);
  scroll-snap-type: y mandatory;
}

#RightInfoContainer.collapsed .Menu {
  border:none;
  width:60px;
}

.loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.Errors {
  width: 300px;
  padding:0 30px;
  height: 40%;
  position: absolute;
  bottom:0px;
  right:0px;
  color: #333;
  background-color:#f5f5f5;
}

.TopMenu {
  width:100%;
  background-color:#FFF;
  border-bottom:1px solid #FFF;
  background-color:#742525;

  ul {
    list-style-type:none;
    margin:0;
    padding:0;

    li {
      margin:0;
      display:inline-block;
      padding:20px 40px;
      text-transform:uppercase;
      border-right:1px solid #FFF;
      color:#FFF;
      &.active {
        font-weight:bold;
      }

      a:hover {
        cursor:pointer;
      }
    }
  }
}

.section {
  scroll-snap-align: start;
  height:500px;
  padding-top:20px;
  position:relative;

  button.next {
    position: absolute;
    bottom: 20px;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #EEE;
  }

  h3 {
    border-bottom: 1px solid #333;
    padding: 10px 0;
  }

  div.row {

    &[data-disabled="true"] {
      visibility:hidden;
    }
    * {
      display:inline-block;
      vertical-align:middle;
      margin-right:10px;
    }
    label {
      font-weight:500;
    }
    span.label {
      display:block;
      padding-bottom:5px;
      font-weight:500;
    }
    padding-bottom:10px;
  }
}

.row-extended[data-rows="1"] {
  display:none;
}

input[data-seat="cinemove"] {

  &:hover {
    cursor:pointer;
  }
  &:nth-child(2) {
    width:30px;
    height:30px;
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      background: #FFF url("http://localhost:3000/assets/images/moveblack.png") no-repeat;
    }
  }
  &:nth-child(3) {
    width:30px;
    height:30px;
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      background: #FFF url("http://localhost:3000/assets/images/movered.png") no-repeat;
    }
  }
}

input[data-seat="roma"] {
  &:nth-child(2) {
    width:30px;
    height:30px;
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      background: #FFF url("http://localhost:3000/assets/images/moveblack.png") no-repeat;
    }
  }
}

label.showcones, label.light {
  background:#FFF url("http://localhost:3000/assets/images/waves.svg") no-repeat;
  background-size:30px;
  width:100px;
  height:25px;
  display:block;
  position:absolute;
  padding:15px 10px 10px 50px;
  border:1px solid #CCC;
  top:80px;
  left:40px;
  border-radius:4px;
  background-position-y:10px;
  background-position-x:10px;
  z-index:1000;
  &:hover {
    cursor:pointer;
  }

  &[data-active="true"] {
    background:#FFF url("http://localhost:3000/assets/images/waves-on.svg") no-repeat;
    background-size:30px;
    background-position-y:10px;
    background-position-x:10px;
  }

  input {
    display:none;
  }
}

label.light {
  top:140px;
  background:#FFF url("http://localhost:3000/assets/images/light.svg") no-repeat;
  background-size:30px;
  background-position-y:10px;
  background-position-x:10px;

  &[data-active="true"] {
    background:#FFF url("http://localhost:3000/assets/images/light-on.svg") no-repeat;
    background-size:30px;
    background-position-y:10px;
    background-position-x:10px;
  }
}

button#collapse, button#expand {
  position:fixed;
  right:361px;
  top:58px;
  font-size:33px;
  background-color:#FFF;
  border:none;
  border-top:1px solid #CCC;
  border-left:1px solid #CCC;
  border-bottom:1px solid #CCC;

  &:hover {
    cursor:pointer;
  }
}

button#expand {
  right:50px;
}

#expand {
  display:none;
}

div.floor {

  input {
    visibility:hidden;
    height:0;
    width:0;
  }

  span.floor-light, span.floor-dark, span.floor-red {

    &:hover {
      cursor:pointer;
    }
    display:block;
    padding:4px;
    width:30px;
    height:30px;
    .inner {
      display: block;
      width: 30px;
      height: 30px;
      background-color: #eeeef0;
    }
  }

  span.floor-light {
    &[data-color="#eeeef0"] {
      padding:3px;
      border:1px solid #333;
    }
  }

  span.floor-dark {
    &[data-color="#101018"] {
      padding:3px;
      border:1px solid #333;
    }
    .inner {
      background-color: #101018;
    }
  }

  span.floor-red {
    &[data-color="#800000"] {
      padding:3px;
      border:1px solid #333;
    }
    .inner {
      background-color: #800000;
    }
  }
}


div.camera {
  .inner {
    border:1px solid #DDD;
    display:inline-block;
    padding:10px;
    background-color:#FFF;
  }
  padding:10px;
  position:absolute;
  bottom:20px;
  left:0;
  right:0;
  text-align:center;
  z-index:1000;
}

button.camera {
  background-color:#FFF;
  &:hover {
    cursor:pointer;
  }
  &:first-child {
    background: #FFF url("http://localhost:3000/assets/images/camera.svg") no-repeat;
    background-position-y:5px;
    background-position-x:10px;
    padding:10px 10px 10px 50px;
  }
  background-size:30px;
  display:inline-block;
  padding:10px;
  border:none;
  border-right:1px solid #DDD;
  &:last-child {
    border-right:0;
  }

}

.summaryForm {
  display:none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  .background {
    width:100%;
    height:100%;
    background-color: #000;
    opacity: 0.8;
  }

  .form {
    position:absolute;
    border-radius:15px;
    top:0;
    margin:150px;
    width:500px;
    max-width:80%;
    background-color:#FFF;
    padding:40px;
    border:2px solid #000;

    button.close {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    input {
      padding:5px;
    }

    .row {
      margin-bottom:20px;
    }
    .label {
      display:inline-block;
      width:150px;
      font-weight:600;
    }
  }
}

@media (max-width:1054px) {
  .TopMenu {
    ul {
      li {
        font-size:14px;
        padding:20px;
      }
    }
  }
}

@media (max-width:782px) {
  .TopMenu {
    height:80px;
    ul {
      li {
        padding:5px 10px;
        border:1px solid #FFF;
        margin:5px;
        border-radius:4px;
      }
    }
  }

  #RightInfoContainer.collapsed {
    width:0px;
  }

  button#collapse, button#expand {
    top:130px;
    z-index:2000;
  }

  button#expand {
    right:0;
  }

  button#collapse {
    right:inherit;
    left:0;
    border-bottom:none;
  }

  .RightInfo {
    width:100%;
    position:fixed;
    top:130px;

    .Menu {
      width:100%;
      margin:0;
      padding:0;

      .section {
        padding:40px;
      }
    }

    .Errors {
      width:100%;
      padding:0;
    }
  }

  .summaryForm .form {
    margin:0;
    top:130px;
  }

  label.showcones, label.light, label.showcones[data-active=true], label.light[data-active=true] {
    top:88px;
    left:10px;
    height:15px;
    background-size:15px;
    padding:8px 10px 10px 30px;
    font-size:14px;
    width:auto;
  }

  label.light, label.light[data-active=true] {
    top:88px;
    left:146px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    width: 150px;
    -webkit-appearance: none;
    background-color: #FFF;
    border:1px solid #000;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 20px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    background: #742525;
    box-shadow: -80px 0 0 80px #a43b3b;
  }

}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #742525;
}
input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}
input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}
